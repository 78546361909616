@import "../../_vars"

div.entry
  margin-bottom: 10px
  padding: 0px
  width: 280px
  margin-left: 5px
  margin-right: 5px
  &:first-of-type
    margin-left: 0
  &:last-of-type
    margin-right: 0
  border: solid 1px #000
  @media(max-width: 350px)
    width: 250px

  div.header
    background-color: var(--mainColor)
    padding: 10px
    display: grid
    color: $fontColor
    grid-template-columns: 72% 28%
    grid-auto-flow: column

    div.header-text
      font-weight: bold
      display: inline
      grid-column: 1

    div.options
      float: right
      grid-column: 2
      text-align: right
  div.body
    padding: 10px
    li
      svg
        vertical-align: text-bottom
    div.joinContainer
      margin-top: 5px!important
    button.join, button.cancel, button.join-all
      display: inline-block
      width: 100%
      margin-top: 10px!important
    .cancel
      cursor: pointer


