@import "_vars"
#mainContainer
  #dashboard
    height: 400px
    margin: 0 auto
    margin-top: 20%
    @media all and (min-width: 800px)
      margin-top: 200px
    text-align: center
  div.kachel
    width: 250px
    height: 200px
    display: inline-block
    font-size: 25px
    &.small
      font-size: 18px
    text-align: center
    background-color: $tvg
    margin: 20px
    margin-left: 0
    margin-right: 20px!important
    &:last-of-type
      margin-right: 0
    padding-top: 30px
    float: left

    div.standard
      display: block
      marginLeft: 40px!important
      marginTop: -40px!important
      margin-bottom: -40px
      color: $fontColor!important
    span.sepa, span.pm_alt
      margin-bottom: 16px
      display: inline-block
      b
        margin-top: 20px
        font-size: 60px
        display: block
        line-height: 40px
      font-size: 30px
      &.pm_alt
        font-size: 24px
    span.sepa
      margin-top: -20px
    small
      color: #444


    &.inactive
      opacity: 0.8
    .icon
      font-size: 100px
      line-height: 120px
      margin-bottom: 10px
    *
      color: $fontColor!important
      svg
        color: white!important
      text-decoration: none
    cursor: pointer
    &.standardmethod
      color: white!important
      background-color: #444!important
      *, a
        color: white!important
      span.ccstars
        color: white!important
    transition: color 0.5s ease-in-out
    &:not(.standardmethod):hover
      .icon, .sepa, .sepa b
        color: $fontColor!important
        transition: color 0.5s ease-in-out
    &:not(.add) a
      border-color: white!important
  span.ccstars
    color: #444!important
    margin-bottom: -15px
    vertical-align: sub
    display: inline-block
