div.location
  margin-bottom: 10px
  padding: 0px
  width: 100%
  border: solid 1px #000

  div.header
    background-color: var(--mainColor)
    color: var(--fontColor)
    padding: 10px
    display: grid
    grid-template-columns: 75% 25%
    grid-auto-flow: column

    div.header-text
      font-weight: bold
      display: inline
      grid-column: 1

    div.options
      float: right
      grid-column: 2
      text-align: right
  div.body
    padding: 10px
    div.menu
      width: 100%
      margin-bottom: 20px
    div.entries
      display: flex
      flex-wrap: wrap

div.deleted
  display: none
div.deletedadmin
  opacity: 60%
.inline
  display: inline
.pointer
  cursor: pointer
