/* thai */
@font-face {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 300;
    src: url(v2/DtVmJx26TKEr37c9YL5rik8s6yLUrwB0lw.woff2) format('woff2');
    unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 300;
    src: url(v2/DtVmJx26TKEr37c9YL5rilQs6yLUrwB0lw.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 300;
    src: url(v2/DtVmJx26TKEr37c9YL5rilUs6yLUrwB0lw.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 300;
    src: url(v2/DtVmJx26TKEr37c9YL5rilss6yLUrwA.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 400;
    src: url(v2/DtVjJx26TKEr37c9aAFJn3YO5gjupg.woff2) format('woff2');
    unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 400;
    src: url(v2/DtVjJx26TKEr37c9aBpJn3YO5gjupg.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 400;
    src: url(v2/DtVjJx26TKEr37c9aBtJn3YO5gjupg.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 400;
    src: url(v2/DtVjJx26TKEr37c9aBVJn3YO5gg.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 500;
    src: url(v2/DtVmJx26TKEr37c9YOZqik8s6yLUrwB0lw.woff2) format('woff2');
    unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 500;
    src: url(v2/DtVmJx26TKEr37c9YOZqilQs6yLUrwB0lw.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 500;
    src: url(v2/DtVmJx26TKEr37c9YOZqilUs6yLUrwB0lw.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 500;
    src: url(v2/DtVmJx26TKEr37c9YOZqilss6yLUrwA.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
/* thai */
@font-face {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 700;
    src: url(v2/DtVmJx26TKEr37c9YK5sik8s6yLUrwB0lw.woff2) format('woff2');
    unicode-range: U+0E01-0E5B, U+200C-200D, U+25CC;
}
/* vietnamese */
@font-face {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 700;
    src: url(v2/DtVmJx26TKEr37c9YK5silQs6yLUrwB0lw.woff2) format('woff2');
    unicode-range: U+0102-0103, U+0110-0111, U+0128-0129, U+0168-0169, U+01A0-01A1, U+01AF-01B0, U+1EA0-1EF9, U+20AB;
}
/* latin-ext */
@font-face {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 700;
    src: url(v2/DtVmJx26TKEr37c9YK5silUs6yLUrwB0lw.woff2) format('woff2');
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF;
}
/* latin */
@font-face {
    font-family: 'Sarabun';
    font-style: normal;
    font-weight: 700;
    src: url(v2/DtVmJx26TKEr37c9YK5silss6yLUrwA.woff2) format('woff2');
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD;
}
