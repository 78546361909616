@import "../_vars.sass"


#courses

  div:not(:last-child)
    padding-bottom: 20px
    margin-bottom: 10px
    border-bottom: 2px solid $tvg
#mainContainer
  div.courselist

    border: 2px solid $tvg
    padding: 20px!important
    margin-bottom: 10px
    max-width: 650px
    cursor: pointer!important
    &:hover
      border-color: #444!important
      .righticon
        border-color: $tvg
      //transition: border 0.5s ease-in-out
    //transition: border 0.5s ease-in-out

    .righticon
      float: right!important
      margin: 10px!important
      margin-top: 30px!important
      margin-right: 30px!important
      width: 50px!important
      height: 50px!important

      border-top: 4px solid #444!important
      border-right: 4px solid #444!important
      transform: rotate(45deg)!important
    em
      margin-left: 0!important
      cursor: pointer

    @media all and (max-width: 500px)
      padding: 10px!important
      margin-left: -10px
      border-left-width: 10px
      margin-right: -10px

#neuigkeiten
  div.courselist
    max-width: 10000px
    h3.sms
      border-bottom: 2px solid $tvg
      padding-bottom: 10px
      margin-top: 0
  img
    max-width: 100%
    height: auto
  div.selectColumns
    width: 100%
    //column-count: 3
    column-width: 200px
    label
      display: block
  div.statsmodal
    float: right


#neue_neuigkeit
  label > span
    display: inline-block
    width: 300px
  div.MuiPrivateTextarea-root-1
    border: 2px solid $tvg
    padding: 20px
    width: calc(100% - 40px)

b.courseTag
  display: inline-block
  border: 1px solid black
  //border-radius: 5px
  padding: 0px 12px 2px 12px
  //line-height: 40px
  margin: 10px
  margin-left: 0
  margin-bottom: 12px
  &:hover
    border-bottom-width: 3px
    margin-bottom: 10px
    cursor: pointer
  &.active
    background-color: $tvg
    margin-bottom: 10px
    color: $fontColor
  @media all and (max-width: 700px)
    //line-height: 20px
    margin-bottom: 2px

$trainerBallSize: 140px
div.trainersList
  margin-top: 20px
  margin-left: -10px
  div
    display: inline-block
    width: $trainerBallSize
    margin: 10px
    height: auto
    vertical-align: top
    span
      display: inline-block
      width: $trainerBallSize
      text-align: center
    img
      border-radius: 100%
      width: $trainerBallSize
      height: auto


#JitsiMeet, #youtubeLivestream
  &, iframe
    max-width: 100%
    margin-bottom: 20px
    margin-top: -10px
    @media all and (max-width: 800px)
      height: 500px!important
    @media all and (max-width: 500px)
      height: 300px!important
