div.container
  display: block
  div.paymentGroup
    cursor: pointer
    position: relative
    padding: 5px
    width: 100%
    margin-right: 10px
    margin-bottom: 10px
    border: solid var(--fontColor) 3px
    display: table
    background-color: var(--mainColor)
    color: var(--fontColor)

    > div
      display: inline-table

    h3
      margin-top: 0px
      margin-bottom: 5px
    ul, p
      margin-top: 2px
      margin-bottom: 2px
    div.buttonGroup
      position: relative
      margin-top: -5px!important
      float: right
  div.selected
    button
      background-color: unset!important
    border: solid black 0px
    color: white
    background-color: #444
    //button

  div.disabled, div.disabled button
    background-color: #eee
    border-color: #888
    color: #888
    cursor: inherit
