#abrechnung
  ul
    list-style: none
    margin-left: 0
    -webkit-padding-start: 0
    -moz-padding-start: 0
    li
      padding: 2px 0

  svg
    margin-bottom: -3px