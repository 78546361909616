@import ../_vars.sass

div.vereinidContainer
  img
    max-height: 140px

#login
  margin: 0 auto
  padding-top: var(--safeAreaInsetTop)
  max-width: 600px

  div:first-of-type
    img
      max-height: 140px
    p
      margin: 0
  h2
    margin-bottom: 0

  label span
    display: inline-block
    width: 150px
    text-align: right
    padding: 20px
  input[type="text"], input[type="password"]
    display: inline-block
    padding: 20px!important
    border: 2px solid $tvg
    width: 300px!important
    line-height: 20px
    &:active, &:focus
      outline: none
    font-size: 15px
    border-radius: 0
    -webkit-appearance: none


  button.maxbtn
    font-size: 15px
    padding: 12px 16px
    background-color: $tvg
    cursor: pointer
    margin: 10px
    border: none
    &:active, &:focus
      outline: none
    color: $fontColor

  text-align: center
  img
    margin-top: 10px


  em
    border-bottom: 1px solid $tvg
    cursor: pointer
    margin: 0 5px
  a:visited, a:link
    color: #444
  a
    text-decoration: none!important
    font-style: italic
    border-bottom: 1px solid $tvg
.MuiTextField-root
  margin-top: 0!important

@media all and (max-width: 500px)
  p
    margin: 0