@import "../_vars"

#buchungen
  label
    &> span
      width: 100px
      display: inline-block
    //margin-right: 20px

.rv-crosshair__line
  background-color: $fontColor

.no_padding
  padding: 0
  margin: 0