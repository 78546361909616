@import "src/vars"
#registerHeader
  & > h2
    text-transform: uppercase
  table
    padding: 0
    max-width: 1200px
    width: 100%
    table-layout: fixed
    tr
      border-spacing: 10px

    td

      //border: 10px solid white
      background: $tvg
      text-align: center
      cursor: pointer
      border-collapse: separate
      border: 0
      border-spacing: 30px!important
      vertical-align: top!important
      &:not(:last-of-type)
        border-right: 10px solid white
      &:not(:first-of-type)
        border-left: 10px solid white
      //border: 2px solid white
      padding: 0


      button
        width: 100%
        font-size: 15px
        color: #404040
        margin: 0!important
        padding: 30px 20px !important

        display: block

        position: relative
        h2
          color: #444
        border-top: 4px solid $tvg!important
      &.active button
        border-top: 4px solid #444!important



#registerHeader
  & > h2
    text-transform: uppercase
  table
    padding: 0
    max-width: 1200px

    table-layout: fixed
    tr
      border-spacing: 10px
      background-color: white!important
    td

      //border: 10px solid white
      background: $tvg
      text-align: center
      cursor: pointer
      border-collapse: separate
      border: 0
      border-spacing: 30px!important
      vertical-align: top!important
      &:not(:last-of-type)
        border-right: 10px solid white
      &:not(:first-of-type)
        border-left: 10px solid white
      //border: 2px solid white
      padding: 0


      button
        width: 100%
        font-size: 15px
        color: #404040
        margin: 0!important
        padding: 30px 20px !important

        display: block

        position: relative
        h2
          color: #444
        border-top: 4px solid $tvg!important
      &.active button
        border-top: 4px solid #444!important

    @media all and (max-width: 600px)
      width: calc(100% - 20px)
      td
        display: block
        margin: 10px 0 10px 0
        border: 0!important

  @media all and (max-width: 380px)
    padding : 10px!important

#abschließen, #kosten
  p
    visibility: visible
    clear: both
    vertical-align: top
div.CheckboxParagraph
  p
    clear: initial!important
    &:first-of-type
      margin-top: 0

